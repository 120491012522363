@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');




.hero-container {
  margin: 0 auto;
  width: 100%;
}

.nav-header {
  height: 100vh;
  position: relative;
  background-image: linear-gradient(
    rgba(34, 34, 34, 0.6),
    rgba(34, 34, 34, 0.6)
  ), url("https://images.unsplash.com/photo-1533777857889-4be7c70b33f7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80");
  background-size: cover;
  color: #fff;

}



.header-container {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.header-container-inner {
  width: 50%;
}

h1 {
  font-size: 52px;
  margin-bottom: 32px;
  line-height: 1.05;
}

p {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 48px;
}

.btn:link,
.btn:visited {
  font-size: 20px;
  font-weight: 600;
  background-color: #119687;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 16px 32px;
  border-radius: 9px;
}

h2 {
  font-size: 44px;
  margin-bottom: 48px;
}

section {
  /*padding: 96px 0;*/
  background-color: #2e3440;
  color:beige;

}

@media (max-width: 768px) {
  h1{
    font-size: 36px;
    text-align: center;
  }

  h2{
    font-size: 30px;
    text-align: center;
  }

  p {
    font-size: 16px;
    line-height: 1.3;
    margin-bottom: 38px;
  }
}