.video-listing {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    width: 100%;
  }
  
  .video-item {
    display: flex;
    min-width: 400px ;
    margin-bottom: 20px;
    margin-top: 25px;
  }
  
  .thumbnail {
    overflow: hidden;
    border-radius: 8px;
  }
  
  .thumbnail  {
    width: 100%;
    height: auto
  }

  .video-link{
    margin-right: 100px;
  }
  
  .video-details {
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 0 0 8px 8px;
  }

  .video-title{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 22px;
    color: #008080;
    font-weight: 500;
    text-align: left;
    margin-bottom: 0px;
  }

  .video-desc{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 16px;
    color: #000;
    opacity: 0.7;
    text-align: left;
  }

  .author{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 14px;
    color: #000;
    opacity: 0.5;
    text-align: left;
    margin-top: 0px;
    margin-left: 20px;
    color:rgb(54, 54, 155);
    text-indent: 0cqb;
  }
  