@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat', sans-serif;
    background-color: #E5E5E5;
}

._container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 30px 0;
}

.flex-vh-center {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 90px 70px;
}

.btn {
    display: inline-block;
    border: none;
    background-color: transparent;
    font-size: 18px;
    cursor: pointer;
}

/* Button 1 */
.btn-1 {
    color: #2E2E2E;
    font-weight: 500;
    border-radius: 30px;
    padding: 18px 47px;
    background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
}

/* Button 2 */
.btn-2 {
    color: #2E2E2E;
    padding: 18px 32px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    border-radius: 20px;
    border: 1px solid #2E2E2E;
}

/* Button 3 */
.btn-3 {
    color: #fff;
    padding: 18px 32px;
    font-weight: 600;
    font-size: 18px;
    border-radius: 30px;
    background: #3A86FF;
}

/* Button 4 */
.btn-4 {
    display: flex;
    align-items: center;
    gap: 15px;

    color: #2E2E2E;
    padding: 18px 32px;
    font-weight: 500;
    font-size: 18px;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 4px 15px rgba(46, 46, 46, 0.15);
}

.btn-4::after {
    font-family: "Material Icons";
    content: '\e5ca';
}

/* Button 5 */
.btn-5 {
    position: relative;
    color: #6D6875;
    padding: 18px 55px;
    font-weight: 600;
    font-size: 18px;
    border-radius: 30px;
    background: #fff;
    overflow: hidden;
}

.btn-5::before {
    content: "";
    position: absolute;
    top: -5%;
    right: -22%;
    transform: rotate(15.6deg);
    border-left: 54px solid transparent;
    border-right: 54px solid transparent;
    border-bottom: 112px solid #D8E2DC;
    z-index: 9;
}
  
.btn-5::after {
    content: "";
    position: absolute;
    top: -170%;
    right: -10%;
    transform: rotate(14.57deg);
    border-left: 54px solid transparent;
    border-right: 54px solid transparent;
    border-bottom: 112px solid #FEC89A;
}
  
.btn-5 span {
    position: absolute;
    top: -70%;
    left: -25%;
    transform: rotate(13.42deg);
    border-left: 54px solid transparent;
    border-right: 54px solid transparent;
    border-bottom: 112px solid #FCD5CE;
}

/* Button 6 */
.btn-6 {
    color: #112A46;
    padding: 18px 32px;
    font-size: 18px;
    background-color: #B8DAFF;
}

/* Button 7 */
.btn-7 {
    height: 36px;
    padding: 0 35px;
    color: #081C15;
    font-size: 18px;
    border: 1px solid #0015D4;
}

/* Button 8 */
.btn-8 {
    width: 70px;
    height: 70px;
    color: #240046;
    border: 1px solid #D3C2FF;
    border-radius: 50%;
}

.btn-8 span {
    font-size: 35px;
}

/* Button 9 */
.btn-9 {
    padding: 18px 32px;
    color: #721300;
    font-size: 18px;
    font-weight: 500;
    background-color: #FF9B54;
    border-radius: 30px;
}

.btn-9::after {
    font-family: "Material Icons";
    content: '\e5cd';
    font-size: 22px;
}

.btn-9 span {
    width: 1px;
    height: 25px;
    background: #721300;
}

/* Button 10 */
.btn-10 {
    color: #fff;
    padding: 21px 32px;
    font-weight: 500;
    font-size: 18px;
    background: linear-gradient(90deg, #ED57B0 0%, #FC6767 100%);
}

.btn-10::after {
    font-family: "Material Icons";
    content: '\e8b6';
    font-size: 22px;
}

/* Button 11 */
.btn-11 {
    color: #01A31B;
    font-weight: 600;
    font-size: 18px;
}

.btn-11::after {
    font-family: "Material Icons";
    content: '\e5e1';
    font-size: 22px;
}

/* Button 12 */
.btn-12 {
    color: #1D3557;
    padding: 13px 32px;
    font-size: 18px;
    border: 1px dashed #E63946;
}

/* Button 13 */
.btn-13 {
    position: relative;
    color: #494949;
    padding: 18px 32px;
    font-weight: 500;
    font-size: 18px;
    background: #FFEE32;
}

.btn-13::before,
.btn-13::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #FFEE32;
    z-index: -1;
    transform: skew(20deg);
}

.btn-13::before {
    left: -10px;
}

.btn-13::after {
    right: -10px;
}

/* Button 14 */
.btn-14 {
    position: relative;
    width: 150px;
    height: 50px;
    background-color: #C9ADA7;
    color: #412C28;
    border-radius: 50%;
}

.btn-14::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 50px;
    border: 1px solid #412C28;
    border-radius: 50%;
    transform: rotate(-9.41deg);
}

/* Button 15 */
.btn-15 {
    color: #555B6E;
    padding: 18px 32px;
    font-size: 18px;
    font-weight: 600;
    background: #BBD0FF;
    box-shadow: inset 0px 5px 5px rgba(85, 91, 110, 0.15);
    border-radius: 30px;
}

/* Button 16 */
.btn-16 {
    color: #6247AA;
    font-size: 18px;
    font-weight: 500;
    text-decoration: underline;
}

/* Button 17 */
.btn-17 {
    color: #52B788;
    padding: 0 0 0 32px;
    font-size: 18px;
    font-weight: 600;
    background-color: #fff;
}

.btn-17::after {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "Material Icons";
    content: '\e2c4';
    font-size: 22px;

    width: 60px;
    height: 60px;
    background-color: #52B788;
    color: #fff;
}

/* Button 18 */
.btn-18 {
    color: #081C15;
    padding: 18px 32px;
    font-weight: 500;
    font-size: 18px;
    background-color: #80ED99;
}

.btn-18::after {
    content: '⟶';
    font-size: 22px;
}

/* Button 19 */
.btn-19 {
    position: relative;
    width: 186px;
    height: 50px;
    color: #2E2E2E;
    padding: 18px 32px;
    font-weight: 500;
    font-size: 18px;
    background-color: #fff;
}

.btn-19::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    width: 186px;
    height: 50px;
    border: 1px solid #2E2E2E;
    z-index: 9;
}