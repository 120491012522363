
  
  
  h1{
    padding: 60px 30px 30px;
    font-weight: 300;
    line-height: 130%;
  }
  /*.horizontal-slider-wrap,*/
  #container{
          width: 100%;
          height: auto;
          padding: 0;
          overflow-y: visible;
          overflow-x: auto;
          position: relative; 
           background-color: black;
    padding: 5px;
      }
      
      #container .horizontal-slider{
          -ms-box-orient: horizontal;
          -ms-display: flex;
          -webkit-display: flex;
          display: -ms-flexbox;
          display: -webkit-box;
          display: flex;
          display: flexbox;
          flex-direction: row;
          width: auto;
          min-width: 100%;
          height: auto;
    
      }
      
      .video-wrap {
          min-width: 300px;
          border: 10px solid black;
          text-align: center;
        
      }
  .video{
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 56%;
    
  }
 /* iframe{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    
  }*/
   @media only screen and (max-width: 450px) {
     .video-wrap {
          min-width: 200px;
  }
}
      
  