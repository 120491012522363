.mission-container {
    position: relative;
    background: #18191b;
    overflow: hidden;
    align-items: center;
    padding: "36px";
    border-radius: 2%;
}

.mission-container:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.6;
    background-image: url('http://placekitten.com/1500/1000');
    background-repeat: no-repeat;
    background-position: 50% 0;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
}



.mission-container >h3{
   color:#fff;
   padding: 50px;
   z-index: 2;
   position: relative;
   text-align: center;

}

.mission-container >h4{
    color:#fff;
    z-index: 2;
    position: relative;
    text-align: center;
    font-size: "26px";
   
 
 }

