  
  .testimonial-container{
    background-color: #393E46;
    color: #fff;
    border-radius: 5px;
    margin: 20px auto;
    padding: 50px 80px;
    max-width: 768px;
    position:relative;
  }
  
  .fa-quote{
    color:#FFD369;
    font-size: 28px;
    position: absolute;
    top: 70px;
  }
  
  .fa-quote-right{
    left: 40px;
  }
  
  .fa-quote-left{
    right: 40px;
  }
  
  .testimonial{
    line-height: 1.7;
    text-align: justify;
    margin-bottom: 30px;
  }
  
  .user{
    display:flex;
    align-items:center;
    justify-content: center;
  }
  
  .user .user-image{
    border-radius: 50%;
    border: 3px solid #FFD369;
    height: 65px;
    width: 65px;
    object-fit: cover;
  }
  
  .user .user-details{
    margin-left: 10px;
  }
  
  .user .username{
    margin: 0;
  }
  
  .user .role{
    font-weight: normal;
    margin: 10px 0;
  }
  
  .progress-bar{
    background-color: #FFD369;
    height: 4px;
    width: 100%;
    margin-bottom: 40px;
    animation: grow 10s linear infinite;
    transform-origin: left;
  }
  
  @keyframes grow{
    0%{
      transform: scaleX(0);
    }
  }
  
  
  
  
  @media(max-width: 768px){
    .testimonial-container{
      padding: 20px 30px;
    }
    
    .fa-quote{
      display: none;
    }
  }