.gallery-container {
    flex-direction: column;
    padding: 2rem;
    background-color: rgb(0, 0, 20);
    background-color:  #001a33!important;
  }
  .gallery-container, .parent-container, .child-container {
    display: flex;
  }
  .parent-container {
    width: 100%;
    justify-content: center;
    gap: 30px 30px;
  }
  .child-container {
    width: 30%;
    height: auto;
    gap: 30px 30px;
    flex-direction: column;
  }
  .child-container img {
    transition: all 1s ease-in-out 0s;
   box-shadow: 2px 2px 20px gray, inset 2px 2px 10px lightgray;
  }
  .child-container img:hover {
    box-shadow: 4px 4px 25px black, inset 2px 2px 2px 10px rgb(0, 0, 20);
   
  }
  
  .greeting {
    text-align: center;
    color: lightblue;
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
  }
  .fa-brands {
    font-size: 50px;
  }
  .greeting, .fa-brands {
    text-shadow: 2px 2px 10px lightgray;
    
  }